<template>
  <div class="search-filter">
    <a-form-model
      class="form"
      ref="form"
      :model="form"
      :label-col="{ flex: '0 0 100px' }"
      :wrapper-col="{ flex: '0 0 calc(100% - 100px)'}"
    >
      <a-row :gutter="16">
        <a-col :span="colSpan">
          <a-form-model-item label="分组名称" prop="name">
            <a-input
              v-model.trim="form.name"
              allow-clear
              placeholder="请输入分组名称"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="colSpan">
          <a-form-model-item label="包含经销商" prop="dealerNameOrStoreCodeOrCompanyName">
            <a-input
              v-model.trim="form.dealerNameOrStoreCodeOrCompanyName"
              allow-clear
              placeholder="请输入名称/编码/公司名"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="colSpan">
          <a-form-model-item label="负责人" prop="leaderId">
             <a-select
              v-model="form.leaderId"
              :default-active-first-option="false"
              :filter-option="false"
              show-search
              style="width: 100%;"
              @search="handleLeaderSearch"
            >
              <a-select-option
                v-for="item in leaderList"
                :key="item.value"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>  
            </a-select> 
          </a-form-model-item>    
        </a-col>
        <a-col :span="colSpan">
          <a-form-model-item label="创建人" prop="creatorId">
             <a-select
              v-model="form.creatorId"
              :default-active-first-option="false"
              option-filter-prop="children"
              show-search
              style="width: 100%;"
            >
              <a-select-option
                v-for="item in creatorList"
                :key="item.value"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>  
            </a-select> 
          </a-form-model-item>    
        </a-col>
        <a-col :span="colSpan2">
          <a-form-model-item :wrapper-col="{ flex: '1 0 auto' }">
            <div class="btn-wrap">
              <a-button class="mr-8" @click="handleReset">
                重置
              </a-button>
              <a-button type="primary" :loading="loading" @click="handleSearch">
                查询
              </a-button>
            </div>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>

<script>
import resizeMixin from '@/mixins/dataTableMixin'

export default {
  name: 'SearchFilter',
  mixins: [resizeMixin],
  props: {
    form: {
      type: Object,
      default() {
        return {}
      }
    },
    fetchLeader: {
      type: Function,
      default() {
        return () => {}
      }
    },
    leaderList: {
      type: Array,
      default() {
        return []
      }
    },
    fetchCreator: {
      type: Function,
      default() {
        return () => {}
      }
    },
    creatorList: {
      type: Array,
      default() {
        return []
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    this.$_resizeHandler = () => {
      this.setColSpan()
    };

    return {
      colSpan: 12,
      colSpan2: 24,
      colSpan3: 24
    }
  },
  mounted() {
    this.fetchCreator()
    this.setColSpan()
  },
  methods: {
    handleLeaderSearch(searchText) {
      this.fetchLeader(searchText)
    },
    handleCreatorSearch(searchText) {
      this.fetchCreator()
    },
    setColSpan() {
      const width = document.documentElement.offsetWidth

      if (width < 1200) {
        this.colSpan = 12
        this.colSpan2 = 24
      } else if (width >= 1200 && width < 1600) {
        this.colSpan = 8
        this.colSpan2 = 16
      } else if (width >= 1600) {
        this.colSpan = 6
        this.colSpan2 = 24
      }
    },
    handleReset() {
      this.$refs.form.resetFields();
      this.fetchLeader('')
      this.fetchCreator('')
      this.$emit('search', {...this.form})
    },
    handleSearch() {
      this.$emit('search', {...this.form})
    }
  }
}
</script>

<style lang="scss" scoped>
.search-filter {
  padding: 24px 24px 16px;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 2px;

  .ant-form-item {
    display: flex;
    margin-bottom: 8px;
  }

  .time-wrap {
    display: flex;
    align-items: center;
    height: 40px;

    .ant-btn-group {
      margin-right: 8px;
    }

    .ant-calendar-picker {
      flex: 1;
    }
  }

  .btn-wrap {
    text-align: right;
  }

  .mr-8 {
    margin-right: 8px;
  }
}
</style>
