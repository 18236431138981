<template>
  <section class="page">
    <SearchFilter
      class="mb-16"
      :form="searchForm"
      :fetch-leader="fetchLeader"
      :leader-list="leaderList"
      :fetch-creator="fetchCreator"
      :creator-list="creatorList"
      :loading="loading"
      @search="handleSearch"
    />

    <DataTable
      :loading="loading"
      :data="dealerPublicGroupList"
      :pagination="pagination"
      @pagination-change="handlePaginationChange"
      @operate="handleOperate"
    />

    <CopyDealerGroupModal
      ref="copyDealerGroupModal"
      v-model="copyDealerGroupVisible"
      :form="copyDealerGroupForm"
      :confirm-loading="copyDealerGroupLoading"
      @staff-select="handleCopyDealerGroupStaffSelect"
      @confirm="handleCopyDealerGroupConfirm"
    />

    <BaseOrgModal
      v-model="baseOrgVisible"
      :checked-data="baseOrgCheckedData"
      :multiple="baseOrgMultiple"
      @confirm="handleBaseOrgConfirm"
      style="z-index: 10000;"
    />
    />
  </section>
</template>

<script>
import SearchFilter from './components/SearchFilter'
import DataTable from './components/DataTable'
import CopyDealerGroupModal from '@/components/CopyDealerGroupModal'
import BaseOrgModal from '@/components/BaseOrgModal'
import dealerApi from '@/api/dealer'

export default {
  name: 'DealerGroupList',
  components: {
    SearchFilter,
    DataTable,
    CopyDealerGroupModal,
    BaseOrgModal
  },
  data() {
    return {
      searchForm: {
        name: '',
        dealerNameOrStoreCodeOrCompanyName: '',
        leaderId: '',
        creatorId: ''
      },
      leaderList: [{ label: '全部', value: '' }],
      creatorList: [{ label: '全部', value: '' }],
      loading: false,
      dealerPublicGroupList: [],
      pagination: {
        page: 1,
        size: 10,
        total: 10
      },
      copyDealerGroupVisible: false,
      copyDealerGroupForm: {
        copyGroupId: '',
        copyGroupName: '',
        copyGroupType: '',
        copyGroupDealerCount: '',
        groupName: '',
        leaders: [],
        coordinators: []
      },
      copyDealerGroupLoading: false,
      baseOrgVisible: false,
      baseOrgCheckedData: [],
      baseOrgMultiple: true
    }
  },
  mounted() {
    const { dealerName } = this.$route.query

    dealerName && (this.searchForm.dealerNameOrStoreCodeOrCompanyName = dealerName)
    this.fetchDealerPublicGroupList()
  },
  methods: {
    async fetchLeader(searchText) {
      if (!searchText) {
        this.leaderList = [{ label: '全部', value: '' }]
        return false
      }

      try {
        const params = {
          employee_name: searchText
        }
        const res = await dealerApi.fetchDealerGroupLeaderList(params)

        this.leaderList = res.map(item => {
          return { label: item.employee_name, value: item.userid }
        })
      } catch (e) {
        console.error(e)
      }
    },
    async fetchCreator(searchText) {
      // if (!searchText) {
      //   this.creatorList = [{ label: '全部', value: '' }]
      //   return false
      // }

      try {
        const params = {}
        const { code, data, message } = await dealerApi.fetchDealerGroupCreatorList(params)

        if (code === 200) {
          this.creatorList = [
            { label: '全部', value: '' },
            ...data.map(item => {
              return { label: item.userOauthName, value: item.creatorId }
            })
          ]
        } else {
          this.$message.error(message)
        }
      } catch (e) {
        console.error(e)
      }
    },
    handleSearch() {
      this.pagination.page = 1
      this.fetchDealerPublicGroupList()
    },
    handlePaginationChange(page, size) {
      this.pagination.page = page
      this.pagination.size = size
      this.fetchDealerPublicGroupList()
    },
    // 获取经销商公共分组列表
    async fetchDealerPublicGroupList() {
      this.loading = true
      try {
        const { page, size } = this.pagination
        const params = {
          ...this.searchForm,
          pageNo: page,
          limit: size
        }
        const { code, data, msg, errmsg } = await dealerApi.fetchDealerPublicGroupList(params)

        this.loading = false

        if (code === 0) {
          this.dealerPublicGroupList = data?.data || []
          this.pagination.total = data?.total || 0
        } else {
          this.$message.error(msg || errmsg)
        }
      } catch (e) {
        console.error(e)
        this.loading = false
      }
    },
    handleOperate(type, record) {
      type === 'follow' && this.followDealerGroup(record)
      type === 'unfollow' && this.unfollowDealerGroup(record)
      type === 'copy' && this.openCopyDealerGroupModal(record)
      type === 'delete' && this.$confirm({
        title: '该分组为公共分组，请谨慎删除！',
        okText: '确认删除',
        onOk: async () => {
          this.deleteDealerGroup(record)
        }
      })
    },
    // 关注经销商分组
    async followDealerGroup(record) {
      try {
        const { groupId, groupName } = record
        const params = {
          groupId,
          groupName,
          groupType: 1
        }
        const { code, message } = await dealerApi.followDealerGroup(params)

        if (code === 200) {
          this.$message.success('关注成功')
          this.fetchDealerPublicGroupList()
        } else {
          this.$message.error(message)
        }
      } catch (e) {
        console.error(e)
      }
    },
    // 取消关注经销商分组
    async unfollowDealerGroup(record) {
      try {
        const { groupId, groupName } = record
        const params = {
          groupId,
          groupName,
          groupType: 1
        }
        const { code, message } = await dealerApi.unfollowDealerGroup(params)

        if (code === 200) {
          this.$message.success('取消关注成功')
          this.fetchDealerPublicGroupList()
        } else {
          this.$message.error(message)
        }
      } catch (e) {
        console.error(e)
      }
    },
    openCopyDealerGroupModal(record) {
      this.copyDealerGroupVisible = true
      this.copyDealerGroupForm.copyGroupId = record.groupId
      this.copyDealerGroupForm.copyGroupName = record.groupName
      this.copyDealerGroupForm.copyGroupType = 1
      this.copyDealerGroupForm.copyGroupDealerCount = record.dealerTotal
    },
    handleCopyDealerGroupStaffSelect(type) {
      this.staffSelectType = type
      this.baseOrgVisible = true

      if (this.staffSelectType === 'leaders') {
        const { leaders } = this.copyDealerGroupForm
        this.baseOrgCheckedData = leaders.length > 0 ? [leaders[0].id] : []
        this.baseOrgMultiple = false
      }

      if (this.staffSelectType === 'coordinators') {
        const { coordinators } = this.copyDealerGroupForm
        this.baseOrgCheckedData = coordinators.length > 0 ? coordinators.map(item => item.id) : []
        this.baseOrgMultiple = true 
      }
    },
    handleBaseOrgConfirm(val) {
      console.log(val)
      if (this.staffSelectType === 'leaders') {
        this.copyDealerGroupForm.leaders = val.checkedUser.map(item => {
          return { id: item.key, name: item.title }
        })
      }

      if (this.staffSelectType === 'coordinators') {
        this.copyDealerGroupForm.coordinators = val.checkedUser.map(item => {
          return { id: item.key, name: item.title }
        })
      }
    },
    handleCopyDealerGroupConfirm() {
      this.copyDealerPublicGroup()
    },
    // 复制经销商公共分组
    async copyDealerPublicGroup() {
      this.copyDealerGroupLoading = true
      try {
        const { copyGroupId, groupName, leaders, coordinators } = this.copyDealerGroupForm
        const params = {
          groupId: copyGroupId,
          groupName,
          leaderId: leaders[0].id,
          coordinatorIds: coordinators.map(coordinator => coordinator.id)
        }
        const { code, errmsg } = await dealerApi.copyDealerPublicGroup(params)

        this.copyDealerGroupLoading = false

        if (code === 0) {
          this.$message.success('复制成功')
          this.copyDealerGroupVisible = false
          this.$refs.copyDealerGroupModal.resetForm()
          this.fetchDealerPublicGroupList()
        } else {
          this.$message.error(errmsg)
        }
      } catch (e) {
        this.copyDealerGroupLoading = false
        console.error(e)
      }
    },
    // 删除经销商分组
    async deleteDealerGroup(record) {
      try {
        const { groupId } = record
        const params = { groupId }
        const { code,  message } = await dealerApi.deleteDealerGroup(params)

        if (code === 200) {
          this.$message.success('删除经销商分组成功')
          this.fetchDealerPublicGroupList()
        } else {
          this.$message.error(message)
        }
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding: 0 !important;
  background: #f0f2f5 !important;
}

.mb-16 {
  margin-bottom: 16px;
}
</style>
